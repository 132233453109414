<template>
  <div
    class="relative flex flex-col w-full min-w-0 mb-6 break-words bg-gray-200 border-0"
  >
    <div class="px-6 py-6 mb-0 bg-white rounded-t">
      <div class="flex items-center justify-between text-center">
        <h6 class="text-xl font-bold text-gray-800">Add New Plan</h6>

        <button @click.prevent="$emit('closeCreateModal')">
          <i class="far fa-times-circle fa-2x"></i>
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 py-10 pt-0 lg:px-10">
      <form @submit.prevent="createPlan">
        <div class="flex flex-wrap py-4 pt-4">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <span
                class="flex items-center block text-xl font-bold text-black uppercase "
                htmlFor="grid-password"
              >
                Multiple

                <input
                  type="checkbox"
                  value="80000"
                  v-model="data.rural"
                  class="w-4 h-4 mx-4"
                />
              </span>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan categories
              </label>

              <v-select
                v-model="data.category"
                multiple
                :options="categories.map((c) => c.title)"
              ></v-select>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12" v-if="!isArchitect">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
               Company Name
              </label>
          <input
                
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="4"
                v-model="data.companyName"
                required
              />
              <!-- <v-select
                v-model="data.architect"
                multiple
                :options="architects.map((c) => c.architectID)"
              ></v-select> -->
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Number Of Bedrooms
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="4"
                v-model.number="data.numberOfBedrooms"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfBedrooms1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfBedrooms2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfBedrooms3"
                  required
                />
              </div>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Number Of Rooms
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="4"
                v-model="data.numberOfRooms"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfRooms1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfRooms2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfRooms3"
                  required
                />
              </div>
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Number Of Bathrooms
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.numberOfBathrooms"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfBathrooms1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfBathrooms2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="numberOfBathrooms3"
                />
              </div>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan Width
              </label>
              <input
                type="text"
                v-if="!data.rural"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.width"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="width1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="width2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="width3"
                />
              </div>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan Length
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.height"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="height1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="height2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="height3"
                />
              </div>
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Garage Capacity
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.capacityOfGarage"
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="capacityOfGarage1"
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="capacityOfGarage2"
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model.number="capacityOfGarage3"
                />
              </div>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Basic Price
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="price"
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Standard Price
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.standard"
                required
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Premium Price
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="premium"
                readonly
              />
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Front Image
              </label>
              <button
                class="p-2 bg-white shadow-md "
                @click.prevent="uploadFrontImage"
                id="frontImageButton"
              >
                Upload Front Image <i class="fa fa-file-image-o"></i>
              </button>

              <div
                class="p-2 mt-4 text-green-500 bg-green-200 rounded-lg "
                v-if="uploaded"
              >
                <i class="fa fa-file-image-o"></i> Successfully Uploaded!.
              </div>
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3 multiple-upload-body">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan Image(s)/Video
              </label>
              <button
                class="p-2 bg-white shadow-md multiple-upload-btn "
                @click.prevent="uploadMultiple"
                id="multipleImageButton"
              >
                Upload Multiple <i class="fa fa-file-image-o"></i>
              </button>

              <div
                class="p-2 mt-4 text-green-500 bg-green-200 rounded-lg"
                v-if="uploadedMultiple"
              >
                <i class="fa fa-file-image-o"></i> Successfully Uploaded!.
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full px-4 lg:w-12/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan Description
              </label>

              <ckeditor
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                rows="4"
                :value="data.description"
                v-model="data.description"
              ></ckeditor>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <button
            class="w-full py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
            type="submit"
          >
            Save Plan
          </button>

          <button
            class="w-full py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
            @click.prevent="$emit('closeCreateModal')"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ls } from "../../helpers";

export default {
  name: "CardCreatePlanForm",
  props: {
    closeCreateModal: Object,
  },
  data() {
    return {
      architectId: "",
      data: {
        rural: false,
        companyName:""
      },
      numberOfBedrooms1: "",
      numberOfBedrooms2: "",
      numberOfBedrooms3: "",
      numberOfBathrooms1: "",
      numberOfBathrooms2: "",
      numberOfBathrooms3: "",
      numberOfRooms1: "",
      numberOfRooms2: "",
      numberOfRooms3: "",
      capacityOfGarage1: "",
      capacityOfGarage2: "",
      capacityOfGarage3: "",
      width1: "",
      width2: "",
      width3: "",
      height1: "",
      height2: "",
      height3: "",

      imageUploadInfo: { url: "" },
      uploaded: false,
      uploadedMultiple: false,
      media: [],
    };
  },
  mounted() {
    this.getCategories();
  },
  computed: {
    categories() {
      return this.$store.state.categories.categories;
    },
    architects() {
      return this.$store.state.users.users.filter((u) => u.role == "architect");
    },
    planStats() {
      return this.$store.state.plans.plans.filter(
        (plan) => plan.architect === this.data.architect
      ).length;
    },
    isArchitect() {
      return this.$store.getters["auth/isArchitect"] ? true : false;
    },
    price() {
      return this.data.standard ? this.data.standard * 0.6 : null;
    },
    premium() {
      return this.data.standard ? this.data.standard * 2.6 : null;
    },
    pictureCategory() {
      return this.data.category ? this.data.category[0] : "";
    },
  },
  methods: {
    async imageUploadWidget() {
      await cloudinary
        .createUploadWidget(
          {
            cloudName: "afroshelter",
            uploadPreset: "ml_default",
            folder: `plan_images/${this.pictureCategory}`,
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.uploaded = true;
              this.data.frontMedia = result.info.secure_url;
            }
          }
        )
        .open();
    },

    async multipleUploadWidget() {
      await cloudinary
        .createUploadWidget(
          {
            cloudName: "afroshelter",
            uploadPreset: "ml_default",
            folder: `plan_images/${this.pictureCategory}`,
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.uploadedMultiple = true;
              this.media.push(result.info.secure_url);
              this.data.planMedia = this.media;
            }
          }
        )
        .open();
    },
    createPlan() {
      //if data.rural is true set data.numberofbedrooms to array of numberofbedrooms1, numberofbedrooms2, numberofbedrooms3
      if (this.data.rural) {
        this.data.numberOfBedrooms = JSON.stringify([
          this.numberOfBedrooms1,
          this.numberOfBedrooms2,
          this.numberOfBedrooms3,
        ]);
        this.data.numberOfRooms = JSON.stringify([
          this.numberOfRooms1,
          this.numberOfRooms2,
          this.numberOfRooms3,
        ]);
        this.data.numberOfBathrooms = JSON.stringify([
          this.numberOfBathrooms1,
          this.numberOfBathrooms2,
          this.numberOfBathrooms3,
        ]);
        this.data.capacityOfGarage = JSON.stringify([
          this.capacityOfGarage1,
          this.capacityOfGarage2,
          this.capacityOfGarage3,
        ]);
        // do the same for width and height as above line
        this.data.width = JSON.stringify([
          this.width1,
          this.width2,
          this.width3,
        ]);
        this.data.height = JSON.stringify([
          this.height1,
          this.height2,
          this.height3,
        ]);
        this.data.rural = true;
      }

      this.$store.getters["auth/isArchitect"]
        ? null
        : (this.data.architect = this.data.architect[0]);

      this.$store.getters["auth/isArchitect"]
        ? (this.data.architect = JSON.parse(ls("userDetails")).architectID)
        : null;

      const zeroPad = (num, places) => String(num).padStart(places, "0");

      if (this.data.rural == false) {
        this.$store.getters["auth/isArchitect"]
          ? (this.data.planID = `${
              JSON.parse(ls("userDetails")).architectID
            }${parseInt(this.data.numberOfBedrooms)}${zeroPad(
              this.planStats + 1,
              2
            )}`)
          : (this.data.planID = `${this.data.architect}${parseInt(
              this.data.numberOfBedrooms
            )}${zeroPad(this.planStats + 1, 2)}`);
      } else {
        this.$store.getters["auth/isArchitect"]
          ? this.numberOfBedrooms3 != ""
            ? (this.data.planID = `${
                JSON.parse(ls("userDetails")).architectID
              }${parseInt(this.numberOfBedrooms1) +
                parseInt(this.numberOfBedrooms2) +
                parseInt(this.numberOfBedrooms3)}${zeroPad(
                this.planStats + 1,
                2
              )}`)
            : (this.data.planID = `${
                JSON.parse(ls("userDetails")).architectID
              }${parseInt(this.numberOfBedrooms1) +
                parseInt(this.numberOfBedrooms2)}${zeroPad(
                this.planStats + 1,
                2
              )}`)
          : this.numberOfBedrooms3 != ""
          ? (this.data.planID = `${this.data.architect}${parseInt(
              this.numberOfBedrooms1
            ) +
              parseInt(this.numberOfBedrooms2) +
              parseInt(this.numberOfBedrooms3)}${zeroPad(
              this.planStats + 1,
              2
            )}`)
          : (this.data.planID = `${this.data.architect}${parseInt(
              this.numberOfBedrooms1
            ) + parseInt(this.numberOfBedrooms2)}${zeroPad(
              this.planStats + 1,
              2
            )}`);
      }

      this.data.price = this.price;
      this.data.premium = this.premium;

      this.$store
        .dispatch("plans/createPlan", this.data)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Plan added Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
    uploadFrontImage() {
      const button = document.getElementById("frontImageButton");
      button.disabled = true;
      return this.imageUploadWidget();
    },
    uploadMultiple() {
      const button = document.getElementById("multipleImageButton");
      button.disabled = true;
      return this.multipleUploadWidget();
    },
    getCategories() {
      return this.$store.dispatch("categories/getCategories");
    },
  },
};
</script>

<style></style>
