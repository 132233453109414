<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex justify-between">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-gray-800' : 'text-white']"
            >
              PAYMENTS
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Plans table -->

      <vs-table striped v-model="selectedPayments">
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
              <vs-checkbox
                :indeterminate="selectedPayments.length == payments.length"
                v-model="allCheck"
                @change="
                  selectedPayments = $vs.checkAll(selectedPayments, payments)
                "
                class="w-8"
              />
            </vs-th>
            <vs-th>
              User Email
            </vs-th>
            <vs-th>
              Order
            </vs-th>
            <vs-th>
              Order Date
            </vs-th>
            <vs-th>
              Status
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <!-- v-for="(tr, i) in " -->
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage(
              $vs.getSearch(payments, search),
              page,
              max
            )"
            :data="tr"
            :is-selected="!!selectedPayments.includes(tr)"
          >
            <vs-td checkbox class="w-8 ">
              <vs-checkbox :val="tr" v-model="selectedPlans" />
            </vs-td>
            <vs-td>
              {{ tr.email }}
            </vs-td>

            <vs-td> {{ tr.items[0] }} </vs-td>
            <vs-td>
              {{ new Date(tr.createdAt).toLocaleString() }}
            </vs-td>
            <vs-td>
              <span v-if="tr.status">
                <h1 class="font-semibold mx-2 text-green-500">
                  Paid
                </h1>
              </span>

              <span v-else>
                <h1 class="font-semibold mx-2 text-red-500">
                  Not Paid
                </h1>
              </span>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination
            v-model="page"
            :length="$vs.getLength(payments, max)"
          />
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      max: 5,
      allCheck: false,
      selectedPayments: [],
      selected: null,
      search: "",
    };
  },

  mounted() {
    this.getPayments();
  },
  computed: {
    payments() {
      return this.$store.state.payments.payments;
    },
  },
  methods: {
    getPayments() {
      this.$store.dispatch("payments/getPayments");
    },
  },
  props: {
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
