<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6   bg-gray-200 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-start">
        <h6 class="text-gray-800 text-xl font-bold">Add New Category</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="createCategory">
        <div class="flex flex-wrap py-4">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-black text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Category Name
              </label>
              <input
                type="text"
                class="px-3 py-3 placeholder-gray-400 text-black bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                value="Plan One"
                v-model="data.title"
                required
              />
            </div>
          </div>
        </div>

        <div class="flex justify-center">
          <button
            class="bg-green-500 text-white hover:scale-110 font-bold uppercase text-xs w-full py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="submit"
          >
            Save Category
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCreateCategoryForm",
  data() {
    return {
      data: {},
    };
  },
  mounted() {},
  methods: {
    createCategory() {
      this.$store
        .dispatch("categories/createCategory", this.data)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Category added  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.$vs.notification({
            position: "top-center",
            color: "danger",
            title: `${error}`,
          });
        });
    },
  },
};
</script>

<style></style>
