<template>
  <div
    class="relative flex flex-col w-full min-w-0 mb-6 break-words rounded shadow-lg"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
  >
    <div class="px-4 py-3 mb-0 border-0 rounded-t">
      <div class="flex flex-wrap items-center">
        <div class="relative flex-1 flex-grow w-full max-w-full px-4">
          <div class="flex justify-between">
            <h3
              class="text-lg font-semibold"
              :class="[color === 'light' ? 'text-gray-800' : 'text-white']"
            >
              AFFILIATES
            </h3>
            <div>
              <a
                class="px-4 py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-400 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
                type="a"
                href="/auth/register"
              >
                Add New Affiliate
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Plans table -->

      <vs-table striped v-model="selected">
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
              UserName
            </vs-th>
            <vs-th>
              FullName
            </vs-th>
            <vs-th>
              Email
            </vs-th>

            <vs-th>
              Reffered
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage(
              $vs.getSearch(users, search),
              page,
              max
            )"
            :data="tr"
          >
            <vs-td>
              {{ tr.username }}
            </vs-td>
            <vs-td>
              {{ tr.fullname }}
            </vs-td>
            <vs-td>
              {{ tr.email }}
            </vs-td>

            <vs-td class="font-semibold">
              {{ refferals(tr._id) }}
            </vs-td>
            <vs-td v-show="currentUser(tr)">
              <button
                class="p-2 m-1 text-white bg-green-800 border"
                type="button"
                v-clipboard:copy="
                  `https://afroshelter.com?affiliateId=${tr._id}`
                "
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                Copy Refferal Link
              </button>

              <button
                class="p-2 m-1 text-white bg-white bg-blue-400 border"
                @click="openEditUser(tr)"
              >
                <i class="fa fa-pencil-square-o"></i>
              </button>
              <button
                class="p-2 m-1 text-white bg-white bg-red-400 border"
                @click="deleteUser(tr._id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(users, max)" />
        </template>
      </vs-table>
    </div>
    <vs-dialog v-model="edit">
      <card-edit-user-form :data="selected" />
    </vs-dialog>
    <vs-dialog v-model="deleteUserModal">
      <div class="p-2 ">
        <h1 class="font-semibold">
          Are You sure you want to delete User!
        </h1>
        <br />
        <div class="flex justify-center">
          <button
            @click="confirmDeleteUser"
            class="p-2 m-2 font-semibold text-white bg-red-500 rounded-lg shadow-md "
          >
            Delete User
          </button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>
<script>
import CardEditUserForm from "./CardEditUserForm.vue";
import { ls } from "../../helpers";
export default {
  data() {
    return {
      page: 1,
      max: 5,
      selected: null,
      search: "",
      active: false,
      edit: false,
      deleteUserModal: false,
      userId: "",
    };
  },
  components: {
    CardEditUserForm,
  },
  mounted() {
    this.getUsers();
    this.getRefferals();
  },
  computed: {
    users() {
      return this.$store.state.users.users.filter(
        (u) => u.role.indexOf("affiliate") > -1
      );
    },
  },
  methods: {
    onCopy: function() {
      this.$vs.notification({
        position: "top-center",
        color: "success",
        title: "Referral Link Copied",
      });
    },
    onError: function() {
      this.$vs.notification({
        position: "top-center",
        color: "danger",
        title: "Referral Link Not Copied!",
      });
    },
    refferals(id) {
      return this.$store.state.affiliates.refferals.filter(
        (r) => r.user_id == id
      ).length;
    },
    currentUser(user) {
      return user.username !== JSON.parse(ls("userDetails")).username;
    },
    getUsers() {
      this.$store.dispatch("users/getUsers");
    },
    deleteUser(id) {
      this.deleteUserModal = true;
      this.userId = id;
    },
    confirmDeleteUser() {
      this.$store.dispatch("users/deleteUser", this.userId);
    },
    getRefferals() {
      this.$store.dispatch("affiliates/getRefferals");
    },
    deleteAllUsers() {
      this.$store.dispatch("users/deleteAllUsers");
    },
    openEditUser(data) {
      this.selected = data;
      this.edit = true;
    },
  },
  props: {
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
