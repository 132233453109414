/* eslint-disable */

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";

let api =
  window.location.hostname === "localhost"
    ? "http://localhost:8090/api"
    : "https://api.afroshelter.com/api";

let lsPrefix = "afroshelter-";

function ls(key) {
  return window.localStorage.getItem(lsPrefix + key);
}

function lsSet(key, val) {
  window.localStorage.setItem(lsPrefix + key, JSON.stringify(val));
}

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const auth = {
  namespaced: true,
  state: {
    token: "",
    userDetails: "",
    loggedIn: ls("token") ? JSON.parse(ls("token")) != "" : false,
    isArchitect: ls("token")
      ? JSON.parse(ls("userDetails")).role.indexOf("admin") > -1
        ? false
        : JSON.parse(ls("userDetails")).role.indexOf("architect") > -1
        ? true
        : false
      : false,
  },
  mutations: {
    loggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    isArchitect(state, isArchitect) {
      state.isArchitect = isArchitect;
    },
  },
  actions: {
    register(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/auth/signup`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/auth/signin`, data)
          .then((response) => {
            if (
              response.data.role.indexOf("architect") > -1 ||
              response.data.role.indexOf("admin") > -1
            ) {
              lsSet("userDetails", response.data);
              lsSet("token", response.data.accessToken);
              resolve(response.data);
            } else {
              reject("You are not an architect or admin");
            }
          })
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    resetPassword(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/auth/recover`, data)
          .then((response) => resolve(response))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    changePassword(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/auth/reset/${data.token}`, data.password)
          .then((response) => resolve(response))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    isArchitect(state) {
      return state.isArchitect;
    },
  },
};

const plans = {
  namespaced: true,
  state: {
    plans: [],
    planStats: "",
  },
  mutations: {
    setPlans(state, payload) {
      state.plans = payload;
    },
    setPlanStat(state, payload) {
      state.planStats = payload;
    },
  },
  actions: {
    createPlan(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/plans/plan`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            if (e.response) {
              reject(e.response.data.message);
            } else if (e.request) {
              reject("Please Your Internet Connection. And Try again. ");
            } else {
              reject("Application Error");
            }
          });
      });
    },

    getPlans({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/plans/`)
          .then((response) => {
            if (JSON.parse(ls("userDetails")).role.indexOf("admin") > -1) {
              commit("setPlans", response.data);
              commit("setPlanStat", response.data.length);
            } else {
              commit(
                "setPlans",
                response.data.filter(
                  (p) =>
                    p.architect == JSON.parse(ls("userDetails")).architectID
                )
              );
              commit(
                "setPlanStat",
                response.data.filter(
                  (p) =>
                    p.architect == JSON.parse(ls("userDetails")).architectID
                ).length
              );
            }

            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },

    getPlansByCategory({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/plans/${data.category}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    editPlan(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${api}/plans/${data._id}`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deletePlan(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/plans/plan/${data}`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteAllPlans(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/plans/`, { data })
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
  },
};

const users = {
  namespaced: true,
  state: {
    users: "",
    userStats: "",
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    setUserStats(state, payload) {
      state.userStats = payload;
    },
  },
  actions: {
    getUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/auth/users`)
          .then((response) => {
            commit("setUsers", response.data);
            commit("setUserStats", response.data.length);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    editUser(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${api}/auth/users/update/${data._id}`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteUser(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/auth/users/delete/${data}`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteAllUsers(store) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/auth/users/delete`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
  },
};

const emails = {
  namespaced: true,
  state: {
    emails: "",
    emailStats: "",
  },
  mutations: {
    setEmails(state, payload) {
      state.emails = payload;
    },
    setEmailStats(state, payload) {
      state.emailStats = payload;
    },
  },
  actions: {
    getEmails({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/emails/`, data)
          .then((response) => {
            commit("setEmails", response.data);
            commit("setEmailStats", response.data.length);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteEmail({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/emails/email/${data}`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteAllEmails({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/emails/`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
  },
};

const categories = {
  namespaced: true,
  state: {
    categories: "",
  },
  mutations: {
    setCategories(state, payload) {
      state.categories = payload;
    },
  },
  actions: {
    createCategory(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/categories/`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    getCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/categories/`)
          .then((response) => {
            commit("setCategories", response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },

    editCategories(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${api}/categories/`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteCategory(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/categories/${data}`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteAllCategories() {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/categories/`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
  },
};

const affiliates = {
  namespaced: true,
  state: {
    refferals: [],
  },
  mutations: {
    setRefferals(state, payload) {
      state.refferals = payload;
    },
  },
  actions: {
    async getRefferals(store, data) {
      await axios
        .get(`${api}/affiliates/`)
        .then((response) => {
          store.commit("setRefferals", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};

const payments = {
  namespaced: true,
  state: {
    payments: [],
  },
  mutations: {
    setPayments(state, payload) {
      state.payments = payload;
    },
  },
  actions: {
    async getPayments(store, data) {
      await axios
        .get(`${api}/payments/`)
        .then((response) => {
          store.commit("setPayments", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};

const chats = {
  namespaced: true,
  state: {
    chat: [],
    chats: [],
    chatID: "",
  },
  mutations: {
    setChat(state, payload) {
      state.chat = payload;
    },
    setChats(state, payload) {
      state.chats = payload;
    },
    setChatID(state, payload) {
      state.chatID = payload;
    },
  },
  actions: {
    async getChat({ commit }, id) {
      await axios
        .get(`${api}/chats/${id}`)
        .then((response) => {
          commit("setChat", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getChats({ commit }, user_id) {
      await axios
        .get(`${api}/chats/chats/${user_id}`)
        .then((response) => {
          commit("setChats", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // write code similar to above for blocking chat
    async blockChat({ commit }, id) {
      await axios
        .put(`${api}/chats/${id}`, { published: false })
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // write above code for unblocking chat
    async unblockChat({ commit }, id) {
      await axios
        .put(`${api}/chats/${id}`, { published: true })
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getAllChats({ commit }) {
      await axios
        .get(`${api}/chats/`)
        .then((response) => {
          commit("setChats", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addMessage(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${api}/chats/${data.id}`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
  },
};

const blog = {
  namespaced: true,
  state: {
    posts: [],
  },
  mutations: {
    setPosts(state, payload) {
      state.posts = payload;
    },
  },
  actions: {
    createPost(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/posts/`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            if (e.response) {
              reject(e.response.data.message);
            } else if (e.request) {
              reject("Please Your Internet Connection. And Try again. ");
            } else {
              reject("Application Error");
            }
          });
      });
    },

    getPosts({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/posts/`)
          .then((response) => {
            commit("setPosts", response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },

    editPost(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${api}/posts/${data._id}`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deletePost(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/posts/${data}`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteAllPosts(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/posts/`, { data })
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
  },
};

const services = {
  namespaced: true,
  state: {
    services: [],
    servicesStats: "",
  },
  mutations: {
    setServices(state, payload) {
      state.services = payload;
    },
    setServicesStats(state, payload) {
      state.servicesStats = payload;
    },
  },
  actions: {
    getServices({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/services/`)
          .then((response) => {
            commit("setServices", response.data);
            commit("setServicesStats", response.data.length);
            console.log(response);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },

    editService(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${api}/services/${data._id}`, data)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },

    deleteService(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/services/${data}`)
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
    deleteAllServices(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${api}/services/`, { data })
          .then((response) => resolve(response.data))
          .catch((e) => {
            reject(e.response.data.message);
          });
      });
    },
  },

  getters: {
    getServices(state) {
      return state.services;
    },
  },
};

export default new Vuex.Store({
  modules: {
    auth,
    plans,
    users,
    emails,
    categories,
    affiliates,
    payments,
    chats,
    blog,
    services,
  },
});
