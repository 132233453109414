<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex justify-between">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-gray-800' : 'text-white']"
            >
              EMAILS
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Plans table -->

      <vs-table striped v-model="selected">
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
              CustomerName
            </vs-th>

            <vs-th>
              Email
            </vs-th>
            <vs-th>
              PhoneNumber
            </vs-th>

            <vs-th>
              Added On
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <!-- v-for="(tr, i) in $vs.getPage(users, page, max) " -->
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage(
              $vs.getSearch(emails, search),
              page,
              max
            )"
            :data="tr"
          >
            <vs-td>
              {{ tr.name }}
            </vs-td>
            <vs-td>
              {{ tr.email }}
            </vs-td>
            <vs-td>
              {{ tr.phoneNumber }}
            </vs-td>

            <vs-td>
              {{ new Date(tr.createdAt).toLocaleString() }}
            </vs-td>
            <vs-td>
              <button
                class="border  bg-white p-2 m-1 bg-red-400 text-white"
                @click="deleteEmail(tr.id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </vs-td>

            <template #expand>
              <div class="con-content">
                <div>
                  <p>
                    {{ tr.message }}
                  </p>
                </div>
              </div>
            </template>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(emails, max)" />
        </template>
      </vs-table>
    </div>
    <vs-dialog v-model="deleteEmailModal">
      <div class="p-2 ">
        <h1 class="font-semibold">
          Are You sure you want to delete Email!
        </h1>
        <br />
        <div class="flex justify-center">
          <button
            @click="confirmDeleteEmail"
            class="bg-red-500 text-white font-semibold p-2 m-2 shadow-md rounded-lg "
          >
            Delete Email
          </button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      max: 5,
      selected: null,
      search: "",
      active: false,
      deleteEmailModal: false,
      emailId: "",
    };
  },
  components: {},
  mounted() {
    this.getEmails();
  },
  computed: {
    emails() {
      return this.$store.state.emails.emails;
    },
  },
  methods: {
    getEmails() {
      this.$store.dispatch("emails/getEmails");
    },
    deleteAllEmails() {
      this.$store.dispatch("emails/deleteAllEmails");
    },
    deleteEmail(id) {
      this.deleteEmailModal = true;
      this.emailId = id;
    },
    confirmDeleteEmail() {
      this.$store
        .dispatch("emails/deleteEmail", this.emailId)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Email delected  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
  },
  props: {
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
