<template>
  <nav
    class="relative z-10 flex flex-wrap items-center justify-between px-6 py-4 bg-white shadow-xl md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden md:w-64"
  >
    <div
      class="flex flex-wrap items-center justify-between w-full px-0 mx-auto md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap"
    >
      <!-- Toggler -->
      <button
        class="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer md:hidden"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <router-link
        class="inline-block p-4 px-0 mr-0 text-sm font-bold text-left text-black uppercase whitespace-no-wrap md:block md:pb-2"
        to="/"
      >
        Afroshelter Admin
      </router-link>
      <!-- User -->
      <ul class="flex flex-wrap items-center list-none md:hidden">
        <li class="relative inline-block">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="absolute top-0 left-0 right-0 z-40 items-center flex-1 h-auto overflow-x-hidden overflow-y-auto rounded shadow md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none"
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="block pb-4 mb-4 border-b border-gray-300 border-solid md:min-w-full md:hidden"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="inline-block p-4 px-0 mr-0 text-sm font-bold text-left text-black uppercase whitespace-no-wrap md:block md:pb-2"
                to="/"
              >
                Afroshelter Admin
              </router-link>
            </div>
            <div class="flex justify-end w-6/12">
              <button
                type="button"
                class="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer md:hidden"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Navigation -->

        <ul class="flex flex-col list-none md:flex-col md:min-w-full">
          <li class="items-center">
            <router-link
              to="/admin/plans"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                Plans
              </a>
            </router-link>
          </li>

              <li class="items-center">
            <router-link
              to="/admin/services"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
             Services
              </a>
            </router-link>
          </li>

          <li class="items-center" v-if="!isArchitect">
            <router-link
              to="/admin/categories"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                Categories
              </a>
            </router-link>
          </li>
          <!-- <li class="items-center" v-if="!isArchitect">
            <router-link to="/admin/blog" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                BLog
              </a>
            </router-link>
          </li> -->
          <li class="items-center" v-if="!isArchitect">
            <router-link
              to="/admin/emails"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                Emails
              </a>
            </router-link>
          </li>

          <li class="items-center" v-if="!isArchitect">
            <router-link
              to="/admin/payments"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                Payments
              </a>
            </router-link>
          </li>

          <li class="items-center" v-if="!isArchitect">
            <router-link
              to="/admin/affiliates"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                Affiliates
              </a>
            </router-link>
          </li>

          <!-- <li class="items-center" v-if="isArchitect">
            <router-link
              to="/admin/inbox"
              v-slot="{ href,  navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                Inbox
              </a>
            </router-link>
          </li>
          <li class="items-center" v-if="!isArchitect">
            <router-link
              to="/admin/chats"
              v-slot="{ href,  navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-table"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                Chats
              </a>
            </router-link>
          </li> -->
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="block pt-1 pb-4 text-xs font-bold text-gray-600 no-underline uppercase md:min-w-full"
        >
          Accounts
        </h6>

 
        <ul class="flex flex-col list-none md:flex-col md:min-w-full md:mb-4">
                 <!-- Navigation -->
        <li class="items-center" v-if="!isArchitect">
          <router-link
            to="/admin/customers"
            v-slot="{ href, navigate, isActive }"
          >
            <a
              :href="href"
              @click="navigate"
              class="block py-3 text-xs font-bold uppercase"
              :class="[
                isActive
                  ? 'text-green-500 hover:text-green-600'
                  : 'text-gray-800 hover:text-gray-600',
              ]"
            >
              <i
                class="mr-2 text-sm fas fa-table"
                :class="[isActive ? 'opacity-75' : 'text-gray-400']"
              ></i>
              Customers
            </a>
          </router-link>
        </li>
          <li class="items-center" v-if="!isArchitect">
            <router-link
              to="/admin/users"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="block py-3 text-xs font-bold uppercase"
                :class="[
                  isActive
                    ? 'text-green-500 hover:text-green-600'
                    : 'text-gray-800 hover:text-gray-600',
                ]"
              >
                <i
                  class="mr-2 text-sm fas fa-users"
                  :class="[isActive ? 'opacity-75' : 'text-gray-400']"
                ></i>
                Users
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <button
              class="block py-3 text-xs font-bold text-gray-800 uppercase hover:text-gray-600"
              @click="logout"
            >
              <i class="mr-2 text-sm text-gray-400 fas fa-fingerprint"></i>
              Logout
            </button>
          </li>

          <li class="items-center" v-if="!isArchitect">
            <router-link
              class="block py-3 text-xs font-bold text-gray-800 uppercase hover:text-gray-600"
              to="/auth/register"
            >
              <i class="mr-2 text-sm text-gray-400 fas fa-clipboard-list"></i>
              Register User
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import { lsClear } from "@/helpers.js";

export default {
  data() {
    return {
      collapseShow: "hidden",
    };
  },
  computed: {
    isArchitect() {
      return this.$store.getters["auth/isArchitect"] ? true : false;
    },
  },
  methods: {
    toggleCollapseShow: function(classes) {
      this.collapseShow = classes;
    },
    logout() {
      lsClear();
      location.reload();
    },
  },
  components: {
    UserDropdown,
  },
};
</script>
