<template>
  <div
    class="relative flex flex-col w-full min-w-0 mb-6 break-words bg-gray-200 border-0"
  >
    <div class="px-6 py-6 mb-0 bg-white rounded-t">
      <div class="flex justify-start text-center">
        <h6 class="text-xl font-bold text-gray-800">Edit User</h6>
      </div>
    </div>
    <div class="flex-auto px-4 py-10 pt-0 lg:px-10">
      <form @submit.prevent="editUser">
        <div class="flex flex-wrap py-4">
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                userName
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="Plan One"
                v-model="data.username"
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                FullName
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                v-model="data.fullname"
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                v-model="data.email"
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                User Role
              </label>

              <v-select
                v-model="data.role"
                multiple
                :options="roles"
              ></v-select>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12" v-if="!isArchitect">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Architect ID
              </label>

              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                v-model="data.architectID"
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Phone Number
              </label>

              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                placeholder="263777777777"
                v-model="data.phoneNumber"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-center">
          <button
            class="w-full py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
            type="submit"
          >
            Save Edits
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCreatePlanForm",
  props: {
    data: Object,
  },

  data() {
    return {
      imageUploadWidget: null,
      multipleUploadWidget: null,
      imageUploadInfo: { url: "" },
      uploaded: false,
      media: [],
      roles: ["admin", "architect", "affiliate", "user"],
    };
  },
  mounted() {
    this.imageUploadWidget = cloudinary.createUploadWidget(
      {
        cloudName: "afroshelter",
        uploadPreset: "ml_default",
        folder: "plan_images",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          this.uploaded = true;
          this.data.frontMedia = result.info.secure_url;
        }
      }
    );
    this.multipleUploadWidget = cloudinary.createUploadWidget(
      {
        cloudName: "afroshelter",
        uploadPreset: "ml_default",
        folder: "plan_images",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          this.uploaded = true;
          this.media.push(result.info.secure_url);
          this.data.planMedia = this.media;
        }
      }
    );

    this.getCategories();
  },
  computed: {
    categories() {
      return this.$store.state.categories.categories;
    },
    isArchitect() {
      return this.$store.getters["auth/isArchitect"] ? true : false;
    },
  },
  methods: {
    deleteFrontMedia() {
      this.data.frontMedia = "";
    },
    arrayRemove(arr, val) {
      return arr.filter((ele) => {
        return ele != val;
      });
    },
    deletePlanMedia(url) {
      this.data.planMedia = this.arrayRemove(this.data.planMedia, url);
    },
    editUser() {
      this.$store
        .dispatch("users/editUser", this.data)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "User edited  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.$vs.notification({
            position: "top-center",
            color: "danger",
            title: `${error}`,
          });
        });
    },
    uploadFrontImage() {
      return this.imageUploadWidget.open();
    },
    uploadMultiple() {
      return this.multipleUploadWidget.open();
    },
    getCategories() {
      return this.$store.dispatch("categories/getCategories");
    },
  },
};
</script>

<style></style>
