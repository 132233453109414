<template>
  <div
    class="relative flex flex-col w-full min-w-0 mb-6 break-words rounded shadow-lg"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
  >
    <div class="px-4 py-3 mb-0 border-0 rounded-t">
      <div class="flex flex-wrap items-center">
        <div class="relative flex-1 flex-grow w-full max-w-full px-4">
          <div class="flex justify-between">
            <h3
              class="text-lg font-semibold"
              :class="[color === 'light' ? 'text-gray-800' : 'text-white']"
            >
           SERVICES
            </h3>
    
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Plans table -->

      <vs-table striped v-model="selected">
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
             Company Name
            </vs-th>
            <vs-th>
      Phone
            </vs-th>
            <vs-th>
        Website
            </vs-th>
            <vs-th>
              Address
            </vs-th>
            <vs-th>
              Published
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage(
              $vs.getSearch(services, search),
              page,
              max
            )"
            :data="tr"
          >
            <vs-td>
              {{ tr.companyName }}
            </vs-td>
            <vs-td>
              {{ tr.phoneNumber }}
            </vs-td>
            <vs-td>
              {{ tr.website }}
            </vs-td>
            <vs-td>
              {{ tr.address }}
            </vs-td>
            <vs-td>
                 <vs-checkbox @change=" updatePublished(tr._id,tr.published)" v-model="tr.published"></vs-checkbox>
            </vs-td>
            <vs-td >
         
              <button
                class="p-2 m-1 text-white  bg-red-400 border"
                @click="deleteService(tr._id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(services, max)" />
        </template>
      </vs-table>
    </div>
    <vs-dialog v-model="edit">
      <card-edit-user-form :data="selected" />
    </vs-dialog>
    <vs-dialog v-model="deleteServiceModal">
      <div class="p-2 ">
        <h1 class="font-semibold">
          Are You sure you want to delete Service!
        </h1>
        <br />
        <div class="flex justify-center">
          <button
            @click="confirmDeleteService()"
            class="p-2 m-2 font-semibold text-white bg-red-500 rounded-lg shadow-md "
          >
            Delete Service
          </button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>
<script>


export default {
  data() {
    return {
      page: 1,
      max: 10,
      selected: null,
      search: "",
      active: false,
      edit: false,
      deleteServiceModal: false,
      serviceId: "",
    };
  },

  mounted() {
    this.getServices();
  },
  computed: {
    services() {
      return this.$store.state.services.services
    },
  },
  methods: {

    getServices() {
      this.$store.dispatch("services/getServices");
    },
    updatePublished(id, status){
      const data = {
        _id: id,
        published: status
      }

      this.$store.dispatch("services/editService", data)

    },
    deleteService(id) {
      this.deleteServiceModal = true;
      this.serviceId = id;
    },
    confirmDeleteService() {
      this.$store
        .dispatch("services/deleteService", this.serviceId)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Service delected  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
    deleteAllServices() {
      this.$store.dispatch("services/deleteAllServices");
    },

  },
  props: {
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
