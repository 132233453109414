<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-gray-500 text-center mb-3 font-bold p-8">
              <h1 class="text-xl">Enter Email For Password Reset</h1>
            </div>
            <form @submit.prevent="resetPassword">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-black text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="px-3 py-3 placeholder-gray-400 text-black bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  v-model="data.email"
                />
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Send Reset Link
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    resetPassword() {
      this.$store
        .dispatch("auth/resetPassword", this.data)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Reset code sent Successfully!",
          });
        })
        .catch((error) => {
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
  },
};
</script>
