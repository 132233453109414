<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex justify-between">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-gray-800' : 'text-white']"
            >
              PLANS
            </h3>
            <div>
              <button
                class="bg-green-400 text-white hover:scale-110 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                @click="openCreateModal"
              >
                Add Plan
              </button>

              <button
                class="border  bg-white p-2  mx-1 bg-red-400 text-white"
                v-if="!isArchitect"
                @click="deleteAllPlans()"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Plans table -->

      <vs-table striped v-model="selectedPlans">
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
              <vs-checkbox
                :indeterminate="selectedPlans.length == plans.length"
                v-model="allCheck"
                @change="selectedPlans = $vs.checkAll(selectedPlans, plans)"
                class="w-8"
              />
            </vs-th>
            <vs-th>
              Plan Id
            </vs-th>
            <vs-th>
              Categories
            </vs-th>

            <vs-th>
              Architect
            </vs-th>
            <vs-th>
              Number Of Rooms
            </vs-th>
            <vs-th>
              Price
            </vs-th>
            <vs-th>
              Added On
            </vs-th>
            <vs-th>
              Edited On
            </vs-th>
            <vs-th>
              Published
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <!-- v-for="(tr, i) in " -->
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage(
              $vs.getSearch(plans, search),
              page,
              max
            )"
            :data="tr"
            :is-selected="!!selectedPlans.includes(tr)"
          >
            <vs-td checkbox class="w-8 ">
              <vs-checkbox :val="tr" v-model="selectedPlans" />
            </vs-td>
            <vs-td>
              {{ tr.planID }}
            </vs-td>
            <vs-td>
              {{ tr.category }}
            </vs-td>
            <vs-td>
              {{ tr.architect }}
            </vs-td>
            <vs-td>
              {{ tr.numberOfRooms }}
            </vs-td>
            <vs-td>
              {{ tr.standard }}
            </vs-td>
            <vs-td>
              <h1 class="font-semibold">
                {{ new Date(tr.createdAt).toLocaleDateString() }}
              </h1>
            </vs-td>
            <vs-td>
              <h1 class="font-semibold">
                {{ new Date(tr.updatedAt).toLocaleDateString() }}
              </h1>
            </vs-td>
            <vs-td>
              <span class="font-semibold text-green-500" v-if="tr.published">
                Yes
              </span>
              <span class="font-semibold text-red-500" v-else>
                Not Yet
              </span>
            </vs-td>
            <vs-td>
              <button
                class="border  bg-white p-2 m-1 bg-blue-400 text-white"
                @click="openEditModal(tr)"
              >
                <i class="fa fa-pencil-square-o"></i>
              </button>

              <button
                class="border  bg-white p-2 m-1 bg-red-400 text-white"
                v-if="canDeletePlan(tr)"
                @click="deletePlan(tr._id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(plans, max)" />
        </template>
      </vs-table>
    </div>
    <modal
      name="createModal"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="false"
    >
      <card-create-plan-form @closeCreateModal="closeCreateModal" />
    </modal>

    <modal
      name="editModal"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="false"
    >
      <card-edit-plan-form :data="selected" @closeEditModal="closeEditModal" />
    </modal>

    <vs-dialog v-model="deletePlanModal">
      <div class="p-2 ">
        <h1 class="font-semibold">
          Are You sure you want to delete this Plan!
        </h1>
        <br />
        <div class="flex justify-center">
          <button
            @click="confirmDeletePlan"
            class="bg-red-500 text-white font-semibold p-2 m-2 shadow-md rounded-lg "
          >
            Delete Plan
          </button>
        </div>
      </div>
    </vs-dialog>

    <vs-dialog v-model="deletePlansModal">
      <div class="p-4 " v-if="selectedPlans.length > 0">
        <h1 class="font-semibold">
          Are You sure you want to delete these Plans!
        </h1>
        <br />
        <div class="flex justify-center">
          <button
            @click="confirmDeletePlans"
            class="bg-red-500 text-white font-semibold p-2 m-2 shadow-md rounded-lg "
          >
            Delete Plans
          </button>
        </div>
      </div>
      <div class="p-4 " v-else>
        <h1 class="font-semibold">
          Please select Plans that you want to delete!
        </h1>
        <br />
      </div>
    </vs-dialog>
  </div>
</template>
<script>
import CardCreatePlanForm from "./CardCreatePlanForm.vue";
import CardEditPlanForm from "./CardEditPlanForm.vue";

export default {
  data() {
    return {
      page: 1,
      max: 5,
      allCheck: false,
      selectedPlans: [],
      selected: null,
      search: "",
      active: false,
      edit: false,
      deletePlanModal: false,
      deletePlansModal: false,
      plansArray: [],
      planId: "",
    };
  },
  components: { CardCreatePlanForm, CardEditPlanForm },
  mounted() {
    this.getPlans();
  },
  computed: {
    plans() {
      return this.$store.state.plans.plans;
    },
    isArchitect() {
      return this.$store.getters["auth/isArchitect"] ? true : false;
    },
  },
  methods: {
    getPlans() {
      this.$store.dispatch("plans/getPlans");
    },
    deletePlan(id) {
      this.deletePlanModal = true;
      this.planId = id;
    },
    deleteAllPlans() {
      this.deletePlansModal = true;
    },
    canDeletePlan(data) {
      return this.$store.getters["auth/isArchitect"] && data.published == true
        ? false
        : this.$store.getters["auth/isArchitect"] && data.published == false
        ? true
        : true;
    },
    confirmDeletePlan() {
      this.$store
        .dispatch("plans/deletePlan", this.planId)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Plan delected  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
    confirmDeletePlans() {
      this.plansArray = this.selectedPlans.map((s) => s._id);

      this.$store
        .dispatch("plans/deleteAllPlans", { plans: this.plansArray })
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Plan(s) deleted Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
    openEditModal(data) {
      this.selected = data;
      this.$modal.show("editModal");
    },
    openCreateModal() {
      this.$modal.show("createModal");
    },
    closeEditModal() {
      this.$modal.hide("editModal");
    },
    closeCreateModal() {
      this.$modal.hide("createModal");
    },
  },
  props: {
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
