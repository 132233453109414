<template>
  <div class="container h-full px-4 mx-auto">
    <div class="flex items-center content-center justify-center h-full">
      <div class="w-full px-4 lg:w-6/12">
        <div
          class="relative flex flex-col w-full min-w-0 mb-6 break-words bg-gray-300 border-0 rounded-lg shadow-lg"
        >
          <div class="flex-auto px-4 py-10 pt-0 lg:px-10">
            <div class="p-8 mb-3 font-bold text-center text-gray-500">
              <h1 class="text-2xl">Register</h1>
            </div>
            <form @submit.prevent="registerUser">
              <div class="relative w-full mb-3">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Role(s)
                </label>

                <v-select
                  v-model="data.role"
                  multiple
                  :options="roles"
                ></v-select>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Name
                </label>
                <input
                  type="text"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="Name"
                  v-model="data.fullname"
                  required
                />
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Surname
                </label>
                <input
                  type="text"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="Surname"
                  v-model="data.surname"
                  required
                />
              </div>

              <div
                class="relative w-full mb-3"
                v-if="data.role == 'admin' || data.role == 'affiliate'"
              >
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Username
                </label>
                <input
                  type="text"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="Username"
                  v-model="data.username"
                  required
                />
              </div>
              <div class="relative w-full mb-3" v-if="data.role == 'architect'">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Architect Username
                </label>
                <input
                  type="text"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="Username"
                  v-model="username"
                  required
                  readonly
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="Email"
                  v-model="data.email"
                  required
                />
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Phone Number
                </label>
                <input
                  type="email"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="263777777777"
                  v-model="data.phoneNumber"
                  required
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Profile Avatar
                </label>
                <a href="" class="button" @click.prevent="uploadAvatar"
                  >Upload Avatar</a
                >
              </div>

              <div class="relative w-full mb-3" v-if="data.role == 'architect'">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Architect ID
                </label>
                <input
                  type="text"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="ID"
                  v-model="data.architectID"
                  required
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="Password"
                  v-model="data.password"
                  required
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block mb-2 text-xs font-bold text-black uppercase"
                  htmlFor="grid-password"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  placeholder="Password"
                  v-model="confirmPassword"
                  required
                />
              </div>

              <div class="mt-6 text-center">
                <button
                  class="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-900 rounded shadow outline-none active:bg-gray-700 hover:shadow-lg focus:outline-none"
                  type="submit"
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      confirmPassword: "",
      imageUploadWidget: null,
      imageUploadInfo: { url: "" },
      uploaded: false,
      roles: ["admin", "architect", "affiliate", "user"],
    };
  },
  mounted() {
    this.imageUploadWidget = cloudinary.createUploadWidget(
      {
        cloudName: "afroshelter",
        uploadPreset: "ml_default",
        folder: "user_images",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          this.uploaded = true;
          this.data.image = result.info.secure_url;
        }
      }
    );
  },
  computed: {
    username() {
      return `${this.data.fullname}@afroshelter`;
    },
  },
  methods: {
    registerUser() {
      this.data.role == "architect"
        ? (this.data.username = this.username)
        : this.data.username;
      if (this.data.password == this.confirmPassword) {
        this.$store
          .dispatch("auth/register", this.data)
          .then(() => {
            this.$vs.notification({
              position: "top-center",
              color: "success",
              title: "User added  Successfully!",
            });
            setTimeout(() => {
              location.href = "/admin/users";
            }, 2000);
          })
          .catch((error) => {
            this.$vs.notification({
              position: "top-center",
              color: "warn",
              title: `${error}`,
            });
          });
      } else {
        this.$vs.notification({
          position: "top-center",
          color: "warn",
          title: "Passwords do not Match!",
        });
      }
    },
    uploadAvatar() {
      return this.imageUploadWidget.open();
    },
  },
};
</script>
