<template>
  <div>
    <a
      class="text-gray-600 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span
          class="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full"
        >
          <img
            alt="user image"
            class="w-full rounded-full align-middle border-none shadow-lg"
            :src="image"
          />
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      >
        Username: {{ username }}
      </a>
      <a
        class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      >
        <button
          class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-full"
          @click="logout"
        >
          <i class="fas fa-lock"></i> Logout
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { ls } from "@/helpers.js";
import { lsClear } from "@/helpers.js";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  computed: {
    image() {
      return JSON.parse(ls("userDetails")).image
        ? JSON.parse(ls("userDetails")).image
        : "https://res.cloudinary.com/afroshelter/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1615289510/default-thumbnail_l7jwwg.jpg";
    },

    username() {
      return JSON.parse(ls("userDetails")).username;
    },
  },
  methods: {
    toggleDropdown: function(event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    logout() {
      lsClear();
      location.reload();
    },
  },
};
</script>
