<template>
  <footer
    class="pb-6"
    :class="[absolute ? 'absolute w-full bottom-0 bg-gray-900' : 'relative']"
  >
    <div class="container px-4 mx-auto">
      <hr class="mb-6 border-gray-700 border-b-1" />
      <div
        class="flex flex-wrap items-center justify-center md:justify-between"
      >
        <div class="w-full px-4 md:w-4/12">
          <div
            class="py-1 text-sm font-semibold text-center text-gray-600 md:text-left"
          >
            Copyright © {{ date }}
            <a
              href="https://cyprian.topiax.xyz"
              class="py-1 text-sm font-semibold text-white hover:text-gray-400"
            >
              Topiax
            </a>
          </div>
        </div>
        <div class="w-full px-4 md:w-8/12">
          <ul class="flex flex-wrap justify-center list-none md:justify-end">
            <li>
              <a
                href="https://.co.zw"
                class="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-400"
              >
                About Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
