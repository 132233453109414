<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex justify-between">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-gray-800' : 'text-white']"
            >
              CATEGORIES
            </h3>
            <div>
              <button
                class="bg-green-400 text-white hover:scale-110 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                @click="active = !active"
              >
                Add Category
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Plans table -->

      <vs-table striped v-model="selected">
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
              Category Name
            </vs-th>

            <vs-th>
              Added On
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <!-- v-for="(tr, i) in $vs.getPage(users, page, max) " -->
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage(
              $vs.getSearch(categories, search),
              page,
              max
            )"
            :data="tr"
          >
            <vs-td>
              {{ tr.title }}
            </vs-td>

            <vs-td>
              {{ new Date(tr.createdAt).toLocaleString() }}
            </vs-td>

            <vs-td>
              <button
                class="border  bg-white p-2 m-1 bg-red-400 text-white"
                @click="deleteCategory(tr.id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination
            v-model="page"
            :length="$vs.getLength(categories, max)"
          />
        </template>
      </vs-table>
    </div>

    <vs-dialog v-model="active">
      <card-create-category-form />
    </vs-dialog>

    <vs-dialog v-model="deleteCategoryModal">
      <div class="p-2 ">
        <h1 class="font-semibold">
          Are You sure you want to delete Category!
        </h1>
        <br />
        <div class="flex justify-center">
          <button
            @click="confirmDeleteCategory"
            class="bg-red-500 text-white font-semibold p-2 m-2 shadow-md rounded-lg "
          >
            Delete Category
          </button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>
<script>
import CardCreateCategoryForm from "./CardCreateCategoryForm.vue";

export default {
  data() {
    return {
      page: 1,
      max: 5,
      search: "",
      active: false,
      deleteCategoryModal: false,
      categoryId: "",
    };
  },
  components: {
    CardCreateCategoryForm,
  },
  mounted() {
    this.getCategories();
  },
  computed: {
    categories() {
      return this.$store.state.categories.categories;
    },
  },
  methods: {
    getCategories() {
      this.$store.dispatch("categories/getCategories");
    },
    deleteAllCategories() {
      this.$store.dispatch("categories/deleteAllCategories");
    },
    deleteCategory(id) {
      this.deleteCategoryModal = true;
      this.categoryId = id;
    },
    confirmDeleteCategory() {
      this.$store
        .dispatch("categories/deleteCategory", this.categoryId)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Plan delected  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
  },
  props: {
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
