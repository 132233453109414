<template>
  <div
    class="relative flex flex-col w-full min-w-0 mb-6 break-words bg-gray-200 border-0"
  >
    <div class="px-6 py-6 mb-0 bg-white rounded-t">
      <div class="flex items-center justify-between text-center">
        <h6 class="text-xl font-bold text-gray-800">Add New Post</h6>
      </div>
    </div>
    <div class="flex-auto px-4 py-10 pt-0 lg:px-10">
      <form @submit.prevent="createPost">
        <div class="flex flex-wrap py-4 pt-4">
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Title
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                v-model="data.title"
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Author
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                v-model="data.author"
                required
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Category
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                v-model="data.category"
                required
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Display Description
              </label>
              <textarea
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                v-model="data.shortDescription"
              />
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Post Image
              </label>
              <button
                class="p-2 bg-white shadow-md "
                @click.prevent="uploadPostImage"
                id="frontImageButton"
              >
                Upload Front Image <i class="fa fa-file-image-o"></i>
              </button>

              <div
                class="p-2 mt-4 text-green-500 bg-green-200 rounded-lg "
                v-if="uploaded"
              >
                <i class="fa fa-file-image-o"></i> Successfully Uploaded!.
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full px-4 lg:w-12/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Post Article
              </label>

              <ckeditor
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                rows="4"
                :value="data.contentHtml"
                v-model="data.contentHtml"
              ></ckeditor>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <button
            class="w-full py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
            type="submit"
          >
            Save Post
          </button>

          <button
            class="w-full py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
            @click.prevent="$emit('closeCreateModal')"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCreatePostForm",
  props: {
    closeCreateModal: Object,
  },
  data() {
    return {
      data: {
        title: "",
        author: "",
        category: "",
        shortDescription: "",
        contentHtml: "",
        image: "",
      },
    };
  },
  mounted() {},
  computed: {},
  methods: {
    async imageUploadWidget() {
      await cloudinary
        .createUploadWidget(
          {
            cloudName: "afroshelter",
            uploadPreset: "ml_default",
            folder: `post_images/${this.data.image}`,
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.uploaded = true;
              this.data.image = result.info.secure_url;
            }
          }
        )
        .open();
    },
    createPost() {
      this.$store
        .dispatch("blog/createPost", this.data)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Post added Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
    uploadPostImage() {
      const button = document.getElementById("frontImageButton");
      button.disabled = true;
      return this.imageUploadWidget();
    },
  },
};
</script>

<style></style>
