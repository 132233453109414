<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex justify-between">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-gray-800' : 'text-white']"
            >
              USERS
            </h3>
            <div>
              <a
                class="bg-green-400 text-white hover:scale-110 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="a"
                href="/auth/register"
              >
                Add New User
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Plans table -->

      <vs-table striped v-model="selected">
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
             Phone
            </vs-th>
            <vs-th>
              FullName
            </vs-th>
            <vs-th>
              Email
            </vs-th>
            <vs-th>
              Role
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage(
              $vs.getSearch(users, search),
              page,
              max
            )"
            :data="tr"
          >
            <vs-td>
              {{ tr.phoneNumber }}
            </vs-td>
            <vs-td>
              {{ tr.fullname }}
            </vs-td>
            <vs-td>
              {{ tr.email }}
            </vs-td>
            <vs-td>
              {{ tr.role }}
            </vs-td>
            <vs-td v-show="currentUser(tr)">
              <button
                class="border  bg-white p-2 m-1 bg-blue-400 text-white"
                @click="openEditUser(tr)"
              >
                <i class="fa fa-pencil-square-o"></i>
              </button>
              <button
                class="border  bg-white p-2 m-1 bg-red-400 text-white"
                @click="deleteUser(tr._id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(users, max)" />
        </template>
      </vs-table>
    </div>
    <vs-dialog v-model="edit">
      <card-edit-user-form :data="selected" />
    </vs-dialog>
    <vs-dialog v-model="deleteUserModal">
      <div class="p-2 ">
        <h1 class="font-semibold">
          Are You sure you want to delete User!
        </h1>
        <br />
        <div class="flex justify-center">
          <button
            @click="confirmDeleteUser"
            class="bg-red-500 text-white font-semibold p-2 m-2 shadow-md rounded-lg "
          >
            Delete User
          </button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>
<script>
import CardEditUserForm from "./CardEditUserForm.vue";
import { ls } from "../../helpers";
export default {
  data() {
    return {
      page: 1,
      max: 5,
      selected: null,
      search: "",
      active: false,
      edit: false,
      deleteUserModal: false,
      userId: "",
    };
  },
  components: {
    CardEditUserForm,
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    users() {
      return this.$store.state.users.users.filter(
        (u) => u.role.indexOf("user") > -1
      );
    },
  },
  methods: {
    currentUser(user) {
      return user.username !== JSON.parse(ls("userDetails")).username;
    },
    getUsers() {
      this.$store.dispatch("users/getUsers");
    },
    deleteUser(id) {
      this.deleteUserModal = true;
      this.userId = id;
    },
    confirmDeleteUser() {
      this.$store
        .dispatch("users/deleteUser", this.userId)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "User delected  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
    deleteAllUsers() {
      this.$store.dispatch("users/deleteAllUsers");
    },
    openEditUser(data) {
      this.selected = data;
      this.edit = true;
    },
  },
  props: {
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
