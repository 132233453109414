<template>
  <!-- Header -->
  <div class="relative bg-green-600 md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-8/12 xl:w-4/12 px-4">
            <card-stats
              statSubtitle="PLANS"
              :statTitle="planStats"
              statPercentColor="text-red-500"
              statIconName="fas fa-home"
              statIconColor="bg-green-500"
            />
          </div>

               <div class="w-full lg:w-8/12 xl:w-4/12 px-4" v-if="!isArchitect">
            <card-stats
              statSubtitle="SERVICES"
              :statTitle="servicesStats"
              statPercentColor="text-red-500"
              statIconName="fas fa-smile"
              statIconColor="bg-blue-500"
            />
          </div>

          <div class="w-full lg:w-8/12 xl:w-4/12 px-4" v-if="!isArchitect">
            <card-stats
              statSubtitle="USERS"
              :statTitle="userStats"
              statPercentColor="text-red-500"
              statIconName="fas fa-smile"
              statIconColor="bg-blue-500"
            />
          </div>
 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";

export default {
  components: {
    CardStats,
  },
  computed: {
    planStats() {
      return this.$store.state.plans.planStats;
    },
    userStats() {
      return this.$store.state.users.userStats;
    },
    servicesStats() {
      return this.$store.state.services.servicesStats;
    },
    isArchitect() {
      return this.$store.getters["auth/isArchitect"] ? true : false;
    },
  },
  methods: {},
};
</script>
