<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <payments-card-table />
    </div>
  </div>
</template>
<script>
import PaymentsCardTable from "@/components/Cards/PaymentsCardTable.vue";

export default {
  components: {
    PaymentsCardTable,
  },
  created() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$store.dispatch("emails/getEmails");
      this.$store.dispatch("users/getUsers");
      this.$store.dispatch("plans/getPlans");
    },
  },
};
</script>
