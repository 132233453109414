<template>
  <div
    class="relative flex flex-col w-full min-w-0 mb-6 break-words rounded shadow-lg"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
  >
    <div class="px-4 py-3 mb-0 border-0 rounded-t">
      <div class="flex flex-wrap items-center">
        <div class="relative flex-1 flex-grow w-full max-w-full px-4">
          <div class="flex justify-between">
            <h3
              class="text-lg font-semibold"
              :class="[color === 'light' ? 'text-gray-800' : 'text-white']"
            >
              Blog Posts
            </h3>
            <div>
              <button
                class="px-4 py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-400 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
                type="button"
                @click="active = !active"
              >
                Add Post
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Plans table -->

      <vs-table striped v-model="selected">
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
              Post Title
            </vs-th>
            <vs-th>
              Post Author
            </vs-th>

            <vs-th>
              Added On
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage(
              $vs.getSearch(posts, search),
              page,
              max
            )"
            :data="tr"
          >
            <vs-td>
              {{ tr.title }}
            </vs-td>
            <vs-td>
              {{ tr.author }}
            </vs-td>

            <vs-td>
              {{ new Date(tr.createdAt).toLocaleString() }}
            </vs-td>

            <vs-td>
              <button
                class="p-2 m-1 text-white bg-white bg-red-400 border"
                @click="deletePost(tr._id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
              <button
                class="p-2 m-1 text-white bg-white bg-blue-400 border"
                @click="editPost(tr._id)"
              >
                <i class="fa fa-pencil"></i>
              </button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(posts, max)" />
        </template>
      </vs-table>
    </div>

    <vs-dialog v-model="active">
      <card-create-post-form />
    </vs-dialog>

    <vs-dialog v-model="deletePostModal">
      <div class="p-2 ">
        <h1 class="font-semibold">
          Are You sure you want to delete Post!
        </h1>
        <br />
        <div class="flex justify-center">
          <button
            @click="confirmDeletePost"
            class="p-2 m-2 font-semibold text-white bg-red-500 rounded-lg shadow-md "
          >
            Delete Post
          </button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>
<script>
import CardCreatePostForm from "./CardCreatePostForm.vue";
// import CardEditPostForm from "./CardEditPostForm.vue";

export default {
  data() {
    return {
      page: 1,
      max: 5,
      search: "",
      active: false,
      deletePostModal: false,
      postId: "",
    };
  },
  components: {
    CardCreatePostForm,
    // CardEditPostForm,
  },
  mounted() {
    this.getPosts();
  },
  computed: {
    posts() {
      return this.$store.state.blog.posts;
    },
  },
  methods: {
    getPosts() {
      this.$store.dispatch("blog/getPosts");
    },
    deleteAllCategories() {
      this.$store.dispatch("blog/deleteAllPosts");
    },
    deletePost(id) {
      this.deletePostModal = true;
      this.postId = id;
    },
    confirmDeletePost() {
      this.$store
        .dispatch("blog/deletePost", this.postId)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Post delected  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.$vs.notification({
            position: "top-center",
            color: "warn",
            title: `${error}`,
          });
        });
    },
  },
  props: {
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
