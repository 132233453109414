<template>
  <div
    class="relative flex flex-col w-full min-w-0 mb-6 break-words bg-gray-200 border-0"
  >
    <div class="px-6 py-6 mb-0 bg-white rounded-t">
      <div class="flex items-center justify-between text-center">
        <h6 class="text-xl font-bold text-gray-800">Edit Plan</h6>

        <button @click.prevent="$emit('closeEditModal')">
          <i class="far fa-times-circle fa-2x"></i>
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 py-10 pt-0 lg:px-10">
      <form>
        <div class="flex flex-wrap py-4">
          <div class="w-full px-4 lg:w-6/12" v-if="data.rural">
            <div class="relative w-full mb-3">
              <span
                class="flex items-center block text-xl font-bold text-black uppercase "
                htmlFor="grid-password"
              >
                Multiple

                <input
                  type="checkbox"
                  value="80000"
                  v-model="data.rural"
                  class="w-4 h-4 mx-4 "
                  disabled
                />
              </span>
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12" v-if="!isArchitect">
            <div class="relative w-full mb-3">
              <span
                class="flex items-center block text-xl font-bold text-black uppercase "
                htmlFor="grid-password"
              >
                Publish

                <input
                  type="checkbox"
                  value="80000"
                  v-model="data.published"
                  class="w-4 h-4 mx-4"
                />
              </span>
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan ID
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="MT1000"
                v-model="data.planID"
                readonly
                required
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan category
              </label>
              <v-select
                v-model="data.category"
                multiple
                :options="categories.map((c) => c.title)"
              ></v-select>
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12" v-if="!isArchitect">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Company Name
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="31123214ID"
                v-model="data.companyName"
                required
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Number Of Bedrooms
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="4"
                v-model="data.numberOfBedrooms"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfBedrooms1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfBedrooms2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfBedrooms3"
                />
              </div>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Number Of Rooms
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="4"
                v-model="data.numberOfRooms"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfRooms1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfRooms2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfRooms3"
                />
              </div>
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Number Of Bathrooms
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.numberOfBathrooms"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfBathrooms1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfBathrooms2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="numberOfBathrooms3"
                />
              </div>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan Width
              </label>
              <input
                type="text"
                v-if="!data.rural"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.width"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="width1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="width2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="width3"
                />
              </div>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan Length
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.height"
                required
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="height1"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="height2"
                  required
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="height3"
                />
              </div>
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Garage Capacity
              </label>
              <input
                v-if="!data.rural"
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.capacityOfGarage"
              />
              <div class="flex justify-between " v-else>
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="capacityOfGarage1"
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="capacityOfGarage2"
                />
                <input
                  type="text"
                  class="w-full px-3 py-3 m-1 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                  value="4"
                  v-model="capacityOfGarage3"
                />
              </div>
            </div>
          </div>

          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Basic Price
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.price"
                required
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Standard Price
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.standard"
                required
              />
            </div>
          </div>
          <div class="w-full px-4 lg:w-6/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Premium Price
              </label>
              <input
                type="text"
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                value="80000"
                v-model.number="data.premium"
                required
              />
            </div>
          </div>

          <div class="w-full px-4" v-if="data.category">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Front Image
              </label>
              <button
                href=""
                class="p-2 bg-white shadow-md "
                @click.prevent="uploadFrontImage"
                id="frontImageButton"
              >
                Upload Front Image <i class="fa fa-file-image-o"></i>
              </button>

              <div
                class="p-2 mt-4 text-green-500 bg-green-200 rounded-lg "
                v-if="uploaded"
              >
                <i class="fa fa-file-image-o"></i> Successfully Uploaded!.
              </div>
              <div v-if="data.frontMedia">
                <img :src="data.frontMedia" class="h-64 p-4" />
                <div class="flex justify-end px-4">
                  <a
                    class="p-1 font-semibold text-red-500 bg-white cursor-pointer"
                    @click.prevent="deleteFrontMedia"
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan Image(s)/Video
              </label>
              <button
                href=""
                class="p-2 bg-white shadow-md "
                @click.prevent="uploadMultiple"
                id="multipleImageButton"
              >
                Upload Multiple <i class="fa fa-file-image-o"></i>
              </button>
              <div
                class="p-2 mt-4 text-green-500 bg-green-200 rounded-lg"
                v-if="uploadedMultiple"
              >
                <i class="fa fa-file-image-o"></i> Successfully Uploaded!.
              </div>
              <draggable v-model="data.planMedia">
                <div v-for="(url, index) in data.planMedia" :key="index">
                  <img
                    v-if="
                      url.slice(url.length - 3) == 'jpg' ||
                        url.slice(url.length - 3) == 'png'
                    "
                    :src="url"
                    alt="plan"
                    class="p-4 h-34"
                  />
                  <video autoplay class="block w-full h-full rounded-md" v-else>
                    <source :src="url" type="video/mp4" />
                    Video tag is not supported in this browser.
                  </video>

                  <div class="flex justify-end px-4">
                    <a
                      class="p-1 font-semibold text-red-500 bg-white cursor-pointer"
                      @click.prevent="deletePlanMedia(url)"
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full px-4 lg:w-12/12">
            <div class="relative w-full mb-3">
              <label
                class="block mb-2 text-xs font-bold text-black uppercase"
                htmlFor="grid-password"
              >
                Plan Description
              </label>

              <ckeditor
                class="w-full px-3 py-3 text-sm text-black placeholder-gray-400 transition-all duration-150 ease-linear bg-white rounded shadow focus:outline-none focus:shadow-outline"
                rows="4"
                :value="data.description"
                v-model="data.description"
              ></ckeditor>
            </div>
          </div>
        </div>

        <div class="flex justify-center">
          <button
            class="w-full py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
            @click.prevent="editPlan"
          >
            Save Edits
          </button>
          <button
            class="w-full py-4 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none hover:scale-110 hover:shadow-md focus:outline-none"
            @click.prevent="$emit('closeEditModal')"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "CardCreatePlanForm",
  props: {
    data: Object,
    closeEditModal: Object,
  },

  data() {
    return {
      imageUploadInfo: { url: "" },
      uploaded: false,
      uploadedMultiple: false,
      media: [],
      numberOfBedrooms1: "",
      numberOfBedrooms2: "",
      numberOfBedrooms3: "",
      numberOfBathrooms1: "",
      numberOfBathrooms2: "",
      numberOfBathrooms3: "",
      numberOfRooms1: "",
      numberOfRooms2: "",
      numberOfRooms3: "",
      capacityOfGarage1: "",
      capacityOfGarage2: "",
      capacityOfGarage3: "",
      width1: "",
      width2: "",
      width3: "",
      height1: "",
      height2: "",
      height3: "",
    };
  },
  components: {
    draggable,
  },
  mounted() {
    this.getCategories();

    if (this.data.rural) {
      this.data.numberOfBedrooms = JSON.parse(this.data.numberOfBedrooms);
      this.data.numberOfBathrooms = JSON.parse(this.data.numberOfBathrooms);
      this.data.numberOfRooms = JSON.parse(this.data.numberOfRooms);
      this.data.capacityOfGarage = JSON.parse(this.data.capacityOfGarage);
      this.data.width = JSON.parse(this.data.width);
      this.data.height = JSON.parse(this.data.height);

      this.numberOfBedrooms1 = this.data.numberOfBedrooms[0];
      this.numberOfBedrooms2 = this.data.numberOfBedrooms[1];
      this.numberOfBedrooms3 = this.data.numberOfBedrooms[2];
      this.numberOfBathrooms1 = this.data.numberOfBathrooms[0];
      this.numberOfBathrooms2 = this.data.numberOfBathrooms[1];
      this.numberOfBathrooms3 = this.data.numberOfBathrooms[2];
      this.numberOfRooms1 = this.data.numberOfRooms[0];
      this.numberOfRooms2 = this.data.numberOfRooms[1];
      this.numberOfRooms3 = this.data.numberOfRooms[2];
      this.capacityOfGarage1 = this.data.capacityOfGarage[0];
      this.capacityOfGarage2 = this.data.capacityOfGarage[1];
      this.capacityOfGarage3 = this.data.capacityOfGarage[2];
      this.width1 = this.data.width[0];
      this.width2 = this.data.width[1];
      this.width3 = this.data.width[2];
      this.height1 = this.data.height[0];
      this.height2 = this.data.height[1];
      this.height3 = this.data.height[2];
    }
  },
  computed: {
    categories() {
      return this.$store.state.categories.categories;
    },
    isArchitect() {
      return this.$store.getters["auth/isArchitect"] ? true : false;
    },
    pictureCategory() {
      return this.data.category ? this.data.category[0] : "";
    },
  },

  methods: {
    async imageUploadWidget() {
      await cloudinary
        .createUploadWidget(
          {
            cloudName: "afroshelter",
            uploadPreset: "ml_default",
            folder: `plan_images/${this.pictureCategory}`,
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.uploaded = true;
              this.data.frontMedia = result.info.secure_url;
            }
          }
        )
        .open();
    },

    async multipleUploadWidget() {
      await cloudinary
        .createUploadWidget(
          {
            cloudName: "afroshelter",
            uploadPreset: "ml_default",
            folder: `plan_images/${this.pictureCategory}`,
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.uploadedMultiple = true;
              this.media.push(result.info.secure_url);
            }
          }
        )
        .open();
    },
    deleteFrontMedia() {
      this.data.frontMedia = "";
    },
    arrayRemove(arr, val) {
      return arr.filter((ele) => {
        return ele != val;
      });
    },
    deletePlanMedia(url) {
      this.data.planMedia = this.arrayRemove(this.data.planMedia, url);
    },
    editPlan() {
      this.data.planMedia = this.data.planMedia.concat(this.media);

      if (this.data.rural) {
        this.data.numberOfBedrooms = JSON.stringify([
          this.numberOfBedrooms1,
          this.numberOfBedrooms2,
          this.numberOfBedrooms3,
        ]);
        this.data.numberOfRooms = JSON.stringify([
          this.numberOfRooms1,
          this.numberOfRooms2,
          this.numberOfRooms3,
        ]);
        this.data.numberOfBathrooms = JSON.stringify([
          this.numberOfBathrooms1,
          this.numberOfBathrooms2,
          this.numberOfBathrooms3,
        ]);
        this.data.capacityOfGarage = JSON.stringify([
          this.capacityOfGarage1,
          this.capacityOfGarage2,
          this.capacityOfGarage3,
        ]);
        // do the same for width and height as above line
        this.data.width = JSON.stringify([
          this.width1,
          this.width2,
          this.width3,
        ]);
        this.data.height = JSON.stringify([
          this.height1,
          this.height2,
          this.height3,
        ]);
        this.data.rural = true;
      }

      this.$store
        .dispatch("plans/editPlan", this.data)
        .then(() => {
          this.$vs.notification({
            position: "top-center",
            color: "success",
            title: "Plan edited  Successfully!",
          });
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.$vs.notification({
            position: "top-center",
            color: "danger",
            title: `${error}`,
          });
        });
    },
    uploadFrontImage() {
      const button = document.getElementById("frontImageButton");
      button.disabled = true;
      return this.imageUploadWidget();
    },
    uploadMultiple() {
      const button = document.getElementById("multipleImageButton");
      button.disabled = true;
      return this.multipleUploadWidget();
    },
    getCategories() {
      return this.$store.dispatch("categories/getCategories");
    },
  },
};
</script>

<style></style>
