/* eslint-disable */

let lsPrefix = "afroshelter-";

export function ls(key) {
  return window.localStorage.getItem(lsPrefix + key);
}

export function lsSet(key, val) {
  window.localStorage.setItem(lsPrefix + key, JSON.stringify(val));
}

export function lsRemove(key, val) {
  window.localStorage.removeItem(lsPrefix + key);
}

export function lsClear() {
  window.localStorage.clear();
}
