<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full px-4 mb-12">
      <blog-card-table />
    </div>
  </div>
</template>
<script>
import BlogCardTable from "@/components/Cards/BlogCardTable.vue";

export default {
  components: {
    BlogCardTable,
  },
  created() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$store.dispatch("emails/getEmails");
      this.$store.dispatch("users/getUsers");
      this.$store.dispatch("plans/getPlans");
      this.$store.dispatch("blog/getPosts");
    },
  },
};
</script>
