import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import Vuesax from "vuesax";
import CKEditor from "ckeditor4-vue";
import vSelect from "vue-select";
import "vuesax/dist/vuesax.css"; //Vuesax styles

import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";

// import { io } from "socket.io-client";
// import VueSocketIOExt from "vue-socket.io-extended";

// if (window.location.hostname === "localhost") {
//   // let api = "http://localhost:8090";
//   // let socket = io(api);
//   // Vue.use(VueSocketIOExt, socket);
// } else {
//   // let api = "https://api.afroshelter.com";
//   // let socket = io(api);
//   // Vue.use(VueSocketIOExt, socket);
// }

Vue.component("v-select", vSelect);

Vue.use(CKEditor);
Vue.use(VModal);

Vue.use(Vuesax, {
  // options here
});

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
Vue.use(VueViewer);
// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "vue-select/dist/vue-select.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

// import Dashboard from "@/views/admin/Dashboard.vue";

import Plans from "@/views/admin/Plans.vue";
import Emails from "@/views/admin/Emails.vue";
import Categories from "@/views/admin/Categories.vue";
import Services from "@/views/admin/Services.vue";
import Blog from "@/views/admin/Blog.vue";
import Users from "@/views/admin/Users.vue";
import Customers from "@/views/admin/Customers.vue";
import Affiliates from "@/views/admin/Affiliates.vue";
import Payments from "@/views/admin/Payments.vue";
// import Inbox from "@/views/admin/Inbox.vue";
// import Chats from "@/views/admin/Chats.vue";

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import Forgotpassword from "@/views/auth/Forgotpassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
// routes

function guardMyroute(to, from, next) {
  let isAuth = false;

  store.getters["auth/loggedIn"] ? (isAuth = true) : (isAuth = false);

  if (isAuth) {
    next();
  } else {
    next("/auth/login");
  }
}

function checkLoggedIn(to, from, next) {
  let isAuth = false;

  store.getters["auth/loggedIn"] ? (isAuth = true) : (isAuth = false);

  if (isAuth) {
    next("/admin/");
  } else {
    next();
  }
}

const routes = [
  {
    path: "/admin",
    redirect: "/admin/plans",
    component: Admin,
    children: [
      {
        path: "/admin/plans",
        beforeEnter: guardMyroute,
        component: Plans,
      },
      {
        path: "/admin/categories",
        beforeEnter: guardMyroute,
        component: Categories,
      },
      {
        path: "/admin/blog",
        beforeEnter: guardMyroute,
        component: Blog,
      },
      {
        path: "/admin/services",
        beforeEnter: guardMyroute,
        component: Services,
      },
      {
        path: "/admin/emails",
        beforeEnter: guardMyroute,
        component: Emails,
      },
      {
        path: "/admin/users",
        beforeEnter: guardMyroute,
        component: Users,
      },
      {
        path: "/admin/customers",
        beforeEnter: guardMyroute,
        component: Customers,
      },
      {
        path: "/admin/affiliates",
        beforeEnter: guardMyroute,
        component: Affiliates,
      },
      // {
      //   path: "/admin/inbox",
      //   beforeEnter: guardMyroute,
      //   component: Inbox,
      // },
      // {
      //   path: "/admin/chats",
      //   beforeEnter: guardMyroute,
      //   component: Chats,
      // },
      {
        path: "/admin/payments",
        beforeEnter: guardMyroute,
        component: Payments,
      },
    ],
  },
  {
    path: "/",
    redirect: "/auth/login",

    component: Auth,
    children: [
      {
        path: "/auth/login",
        beforeEnter: checkLoggedIn,
        component: Login,
      },
      {
        path: "/auth/register",
        beforeEnter: guardMyroute,
        component: Register,
      },
      {
        path: "/auth/forgot-password",
        component: Forgotpassword,
      },
      {
        path: "/auth/reset-password/:token",
        component: ResetPassword,
      },
    ],
  },

  { path: "*", redirect: "/" },
];

// app config

Vue.config.productionTip = false;

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
